import {Injectable} from '@angular/core'
import {BehaviorSubject} from "rxjs"


@Injectable({
    providedIn: 'root'
})
export class StatesService {

    mapPoint1Hovered$ = new BehaviorSubject<boolean>(false)
    mapPoint2Hovered$ = new BehaviorSubject<boolean>(false)
    mapPoint3Hovered$ = new BehaviorSubject<boolean>(false)

    galleryShop1Hovered$ = new BehaviorSubject<boolean>(false)
    galleryShop2Hovered$ = new BehaviorSubject<boolean>(false)
    galleryShop3Hovered$ = new BehaviorSubject<boolean>(false)

    galleryImageHeight$ = new BehaviorSubject<number>(0)
    galleryContainerBottom$ = new BehaviorSubject<number>(0)
    galleryContainerTop$ = new BehaviorSubject<number>(0)

    visibleShopUrl$ = new BehaviorSubject<string|null>(null)
    shopItemNumber$ = new BehaviorSubject<number>(0)
    isMobile$ = new BehaviorSubject<boolean>(false)


    constructor() {
    }

}
