import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
// import {IschglRentMapComponent} from "./elements/ischgl-rent-map/ischgl-rent-map.component"
import {createCustomElement} from "@angular/elements"
import {IschglRentGalleryComponent} from "./elements/ischgl-rent-gallery/ischgl-rent-gallery.component"
// import {IschglRentCarouselComponent} from "./elements/ischgl-rent-carousel/ischgl-rent-carousel.component"
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"


@NgModule({
    declarations: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
    ],
    providers: [],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {


    constructor(injector: Injector) {
        // // Map-Element
        // const mapElement = createCustomElement(IschglRentMapComponent, {injector})
        // customElements.define("ischgl-rent-map", mapElement)

        // Gallery-Element
        const galleryElement = createCustomElement(IschglRentGalleryComponent, {injector})
        customElements.define("ischgl-rent-gallery", galleryElement)

        // // Carousel-Element
        // const carouselElement = createCustomElement(IschglRentCarouselComponent, {injector})
        // customElements.define("ischgl-rent-carousel", carouselElement)
    }


    ngDoBootstrap() {

    }

}
