import { Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GalleryItemComponent } from "./gallery-item/gallery-item.component"
import { ShopItemComponent } from "./shop-item/shop-item.component"
import { StatesService } from "../../services/states.service"
import { Subscription } from "rxjs"


@Component({
    selector: 'ischgl-rent-gallery',
    standalone: true,
    imports: [
        CommonModule,
        GalleryItemComponent,
        ShopItemComponent,
    ],
    templateUrl: './ischgl-rent-gallery.component.html',
    styleUrls: ['./ischgl-rent-gallery.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class IschglRentGalleryComponent implements OnInit, OnDestroy {

    @Input("shop-1-image-url") defaultShop1ImageUrl!: string
    @Input("shop-1-image-active-url") defaultShop1ImageActiveUrl!: string
    @Input("shop-1-content-url") shop1ContentUrl!: string
    @Input("shop-1-mobile-image-url") defaultShop1MobileImageUrl!: string
    @Input("shop-1-mobile-image-hover-url") defaultShop1MobileImageHoverUrl!: string
    @Input("shop-1-mobile-image-active-url") defaultShop1MobileImageActiveUrl!: string
    @Input("shop-1-mobile-image-inactive-url") defaultShop1MobileImageInactiveUrl!: string
    shop1ImageUrl!: string
    shop1ImageActiveUrl!: string

    @Input("shop-2-image-url") defaultShop2ImageUrl!: string
    @Input("shop-2-image-active-url") defaultShop2ImageActiveUrl!: string
    @Input("shop-2-content-url") shop2ContentUrl!: string
    @Input("shop-2-mobile-image-url") defaultShop2MobileImageUrl!: string
    @Input("shop-2-mobile-image-hover-url") defaultShop2MobileImageHoverUrl!: string
    @Input("shop-2-mobile-image-active-url") defaultShop2MobileImageActiveUrl!: string
    @Input("shop-2-mobile-image-inactive-url") defaultShop2MobileImageInactiveUrl!: string
    shop2ImageUrl!: string
    shop2ImageActiveUrl!: string

    @Input("shop-3-image-url") defaultShop3ImageUrl!: string
    @Input("shop-3-image-active-url") defaultShop3ImageActiveUrl!: string
    @Input("shop-3-content-url") shop3ContentUrl!: string
    @Input("shop-3-mobile-image-url") defaultShop3MobileImageUrl!: string
    @Input("shop-3-mobile-image-hover-url") defaultShop3MobileImageHoverUrl!: string
    @Input("shop-3-mobile-image-active-url") defaultShop3MobileImageActiveUrl!: string
    @Input("shop-3-mobile-image-inactive-url") defaultShop3MobileImageInactiveUrl!: string
    shop3ImageUrl!: string
    shop3ImageActiveUrl!: string

    @Input("close-icon-url") closeIconUrl!: string

    mapPoint1Hovered$ = this.statesService.mapPoint1Hovered$
    mapPoint2Hovered$ = this.statesService.mapPoint2Hovered$
    mapPoint3Hovered$ = this.statesService.mapPoint3Hovered$

    galleryShop1Hovered$ = this.statesService.galleryShop1Hovered$
    galleryShop2Hovered$ = this.statesService.galleryShop2Hovered$
    galleryShop3Hovered$ = this.statesService.galleryShop3Hovered$

    visibleShopUrl$ = this.statesService.visibleShopUrl$
    shopItemNumber$ = this.statesService.shopItemNumber$
    showShopItemSubscription!: Subscription

    @ViewChild("galleryContainer") galleryContainerRef!: ElementRef<HTMLDivElement>


    constructor(
        private statesService: StatesService,
        private ngZone: NgZone,
    ) {
    }


    @HostListener("window:resize")
    onWindowResize() {
        const boundingRect = this.galleryContainerRef.nativeElement.getBoundingClientRect()
        const bottom: number = boundingRect.bottom + window.scrollY
        this.statesService.galleryContainerTop$.next(boundingRect.top + window.scrollY)
        this.statesService.galleryContainerBottom$.next(bottom)

        // Je nach Breite die mobile Ansicht aktivieren
        this.statesService.isMobile$.next(window.innerWidth < 992)

        const shopNumber = this.statesService.shopItemNumber$.value
        if (this.statesService.isMobile$.value) {
            // Mobile Ansicht
            if (shopNumber === 0) {
                this.shop1ImageUrl = this.defaultShop1MobileImageUrl
                this.shop1ImageActiveUrl = this.defaultShop1MobileImageHoverUrl
                this.shop2ImageUrl = this.defaultShop2MobileImageUrl
                this.shop2ImageActiveUrl = this.defaultShop2MobileImageHoverUrl
                this.shop3ImageUrl = this.defaultShop3MobileImageUrl
                this.shop3ImageActiveUrl = this.defaultShop3MobileImageHoverUrl
            } else {
                this.shop1ImageUrl = this.defaultShop1MobileImageInactiveUrl
                this.shop1ImageActiveUrl = this.defaultShop1MobileImageActiveUrl
                this.shop2ImageUrl = this.defaultShop2MobileImageInactiveUrl
                this.shop2ImageActiveUrl = this.defaultShop2MobileImageActiveUrl
                this.shop3ImageUrl = this.defaultShop3MobileImageInactiveUrl
                this.shop3ImageActiveUrl = this.defaultShop3MobileImageActiveUrl
            }
        } else {
            // Desktop Ansicht
            this.shop1ImageUrl = this.defaultShop1ImageUrl
            this.shop1ImageActiveUrl = this.defaultShop1ImageActiveUrl
            this.shop2ImageUrl = this.defaultShop2ImageUrl
            this.shop2ImageActiveUrl = this.defaultShop2ImageActiveUrl
            this.shop3ImageUrl = this.defaultShop3ImageUrl
            this.shop3ImageActiveUrl = this.defaultShop3ImageActiveUrl
        }

    }


    ngOnInit(): void {

        // Ständig die Positionsdaten der Gallery ermitteln
        setInterval(this.onWindowResize.bind(this), 300)

        // Darauf horchen, falls ein Shop angezeigt werden soll
        this.showShopItemSubscription = this.shopItemNumber$.subscribe((shopNumber) => {

            // Gültige URLs ermitteln
            const shop1ContentUrl = new URL(this.shop1ContentUrl, window.location.origin)
            const shop2ContentUrl = new URL(this.shop2ContentUrl, window.location.origin)
            const shop3ContentUrl = new URL(this.shop3ContentUrl, window.location.origin)

            const iframeUrl = new Map()
            iframeUrl.set(0, null)
            iframeUrl.set(1, shop1ContentUrl)
            iframeUrl.set(2, shop2ContentUrl)
            iframeUrl.set(3, shop3ContentUrl)
            this.visibleShopUrl$.next(iframeUrl.get(shopNumber))

            switch (shopNumber) {
                case 1:
                    this.mapPoint1Hovered$.next(true)
                    this.mapPoint2Hovered$.next(false)
                    this.mapPoint3Hovered$.next(false)
                    break
                case 2:
                    this.mapPoint1Hovered$.next(false)
                    this.mapPoint2Hovered$.next(true)
                    this.mapPoint3Hovered$.next(false)
                    break
                case 3:
                    this.mapPoint1Hovered$.next(false)
                    this.mapPoint2Hovered$.next(false)
                    this.mapPoint3Hovered$.next(true)
                    break
                default:
                    this.mapPoint1Hovered$.next(false)
                    this.mapPoint2Hovered$.next(false)
                    this.mapPoint3Hovered$.next(false)
            }

            if (shopNumber) {
                // Zum Bild scrollen
                const galleryContainerTop = this.statesService.galleryContainerTop$.value
                const galleryContainerBottom = this.statesService.galleryContainerBottom$.value
                const galleryImageHeight = this.statesService.galleryImageHeight$.value
                let scrollPos: number
                if (this.statesService.isMobile$.value) {
                    scrollPos = galleryContainerTop
                } else {
                    scrollPos = galleryContainerBottom - (galleryImageHeight / 100 * 40)
                }
                this.ngZone.runOutsideAngular(() => {
                    window.scrollTo({top: scrollPos, behavior: "smooth"})
                })
            }
        })

    }


    ngOnDestroy() {
        if (this.showShopItemSubscription) {
            this.showShopItemSubscription.unsubscribe()
        }
    }

}



