<!-- Galerie BEGIN -->
<div
  #galleryContainer
  class="gallery-container row gx-1 gy-0"
>
  <!--  [class.hidden]="(visibleShopUrl$ | async)"-->

  <!-- Shop 1 BEGIN -->
  <div class="col-12 col-lg-4 order-lg-0"
    [ngClass]="{'order-last': shopItemNumber$.value === 1}"
  >
    <app-gallery-item
      [shop-image-url]="shop1ImageUrl"
      [shop-image-active-url]="shop1ImageActiveUrl"
      [hovered$]="galleryShop1Hovered$"
      [mapPointHovered$]="mapPoint1Hovered$"
      (click)="shopItemNumber$.next(1)"
    ></app-gallery-item>
  </div>
  <!-- Shop 1 END -->

  <!-- Shop 2 BEGIN -->
  <div class="col-12 col-lg-4 order-lg-1"
    [ngClass]="{'order-last': shopItemNumber$.value === 2}"
  >
    <app-gallery-item
      [shop-image-url]="shop2ImageUrl"
      [shop-image-active-url]="shop2ImageActiveUrl"
      [hovered$]="galleryShop2Hovered$"
      [mapPointHovered$]="mapPoint2Hovered$"
      (click)="shopItemNumber$.next(2)"
    ></app-gallery-item>
  </div>
  <!-- Shop 2 END -->

  <!-- Shop 3 BEGIN -->
  <div class="col-12 col-lg-4 order-lg-2"
    [ngClass]="{'order-last': shopItemNumber$.value === 3}"
  >
    <app-gallery-item
      [shop-image-url]="shop3ImageUrl"
      [shop-image-active-url]="shop3ImageActiveUrl"
      [hovered$]="galleryShop3Hovered$"
      [mapPointHovered$]="mapPoint3Hovered$"
      (click)="shopItemNumber$.next(3)"
    ></app-gallery-item>
  </div>
  <!-- Shop 3 END -->

</div>
<!-- Galerie END -->

<!-- Shop-Anzeige BEGIN -->
<div class="shop-container">
  <app-shop-item
    [close-icon-url]="closeIconUrl"
  ></app-shop-item>
</div>
<!-- Shop-Anzeige END -->

