import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HttpClientModule} from "@angular/common/http"
import {StatesService} from "../../../services/states.service"
import {distinctUntilChanged, Subscription} from "rxjs"
import {v4 as uuidv4} from "uuid"


@Component({
    selector: 'app-shop-item',
    standalone: true,
    imports: [
        CommonModule,
        HttpClientModule
    ],
    templateUrl: './shop-item.component.html',
    styleUrls: ['./shop-item.component.scss']
})
export class ShopItemComponent implements OnInit, OnDestroy {

    @Input("close-icon-url") closeIconUrl!: string
    @ViewChild("iframeContainer") iframeContainer!: ElementRef<HTMLDivElement>
    iframe: HTMLIFrameElement | null = null
    iframeInitialized = false
    private visibleShopUrlSubscription!: Subscription
    private oldHeight = 0
    private oldIframeWidth = 0
    readonly iframeKey: string = uuidv4().toString()
    private currentIframeUrl: string | null = null


    constructor(
        private statesService: StatesService,
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {
    }


    ngOnInit() {
        this.visibleShopUrlSubscription = this.statesService.visibleShopUrl$.pipe(
            distinctUntilChanged()
        ).subscribe((iframeUrl) => {
            console.debug(`ShopItemComponent.iframeUrlSetter(${iframeUrl})`)

            this.currentIframeUrl = iframeUrl

            // Altes Iframe und Event-Listener entfernen
            if (this.iframe) {
                this.iframeContainer.nativeElement.removeChild(this.iframe)
                this.iframe = null
                this.iframeInitialized = false
                this.oldHeight = 0
                this.oldIframeWidth = 0
                window.removeEventListener("message", this.onWindowMessageHandler)
            }

            if (!iframeUrl) {
                return
            }

            const minHeight = this.statesService.galleryImageHeight$.value

            // Neues Iframe generieren
            this.iframe = document.createElement("iframe") as HTMLIFrameElement
            this.iframe.setAttribute("hspace", "0")
            this.iframe.setAttribute("vspace", "0")
            this.iframe.setAttribute("marginheight", "0")
            this.iframe.setAttribute("marginwidth", "0")
            this.iframe.setAttribute("scrolling", "no")
            this.iframe.setAttribute("width", "100%")
            this.iframe.setAttribute("height", `${minHeight}px`)
            this.iframe.setAttribute("frameborder", "0")
            this.iframe.style.overflow = "hidden"
            this.iframe.style.height = `${minHeight}px`
            this.iframe.src = iframeUrl + "?iframe-key=" + this.iframeKey

            // Iframe hinzufügen
            this.iframeContainer.nativeElement.append(this.iframe)

            // Event-Listener für Nachrichten binden
            window.addEventListener("message", this.onWindowMessageHandler, true)

        })
    }


    ngOnDestroy() {
        if (this.visibleShopUrlSubscription) {
            this.visibleShopUrlSubscription.unsubscribe()
        }
    }


    onClose(event: Event) {
        event.preventDefault()
        this.statesService.shopItemNumber$.next(0)
    }


    onWindowMessageHandler = (event: MessageEvent) => {
        // Iframe-Key prüfen
        const iFrameKey = event.data?.iframeKey as string
        if (iFrameKey !== this.iframeKey) {
            return
        }
        // Iframe-Origin prüfen
        const iframeOrigin = new URL(this.currentIframeUrl as string).origin
        if (iframeOrigin !== event.origin) {
            return
        }

        // Close-Icon-Position anpassen
        const iframeWidth = this.iframe?.offsetWidth as number
        if (iframeWidth !== this.oldIframeWidth) {

            const closeIconContainer = this.elementRef.nativeElement.querySelector(".close-icon-container")
            if (!!closeIconContainer) {
                let iconTop = 0
                let iconTopStyle = ""

                // if (iframeWidth < 300) {
                //     iconTop = iframeWidth / 1.9
                // } else if (iframeWidth < 500) {
                //     iconTop = iframeWidth / 2.2
                // } else if (iframeWidth < 1025) {
                //     iconTop = iframeWidth / 2.3
                // }

                if (iconTop === 0) {
                    iconTopStyle = "1rem"
                } else {
                    iconTopStyle = `${iconTop}px`
                }
                this.renderer.setStyle(closeIconContainer, "top", iconTopStyle)

                // Erst dann die alte Breite setzen, wenn mindestens einmal das Icon angepasst wurde.
                this.oldIframeWidth = iframeWidth
            }

        }


        // Höhe ermitteln
        const height = event.data?.height as number
        if (height === this.oldHeight) {
            return
        }
        this.oldHeight = height

        // Höhe des Iframes anpassen
        console.debug(`ShopItemComponent.onWindowMessageHandler(${height})`)
        if (!!height) {
            if (!!this.iframe) {
                if (this.iframeInitialized) {
                    this.iframe.style.transition = "height 100ms"
                } else {
                    this.iframe.style.transition = "height 1500ms"
                    this.iframeInitialized = true
                }
                this.renderer.setStyle(this.iframe, "height", `${height}px`)
            }
        }

    }

}
