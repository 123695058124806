import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import {BehaviorSubject} from "rxjs"
import {StatesService} from "../../../services/states.service"


@Component({
    selector: 'app-gallery-item',
    standalone: true,
    imports: [CommonModule, NgOptimizedImage],
    templateUrl: './gallery-item.component.html',
    styleUrls: ['./gallery-item.component.scss']
})
export class GalleryItemComponent implements OnInit {

    @Input("shop-image-url") shopImageUrl!: string
    @Input("shop-image-active-url") shopImageActiveUrl!: string
    @Input("mapPointHovered$") mapPointHovered$!: BehaviorSubject<boolean>
    @Input("hovered$") hovered$!: BehaviorSubject<boolean>

    @Output() click = new EventEmitter<void>()

    @ViewChild("image") shopImage!: ElementRef<HTMLImageElement>
    @ViewChild("imageactive") shopImageActive!: ElementRef<HTMLImageElement>
    // @ViewChild("darkfilter") darkFilterDiv!: ElementRef<HTMLDivElement>
    // @ViewChild("typo") typoImage!: ElementRef<HTMLImageElement>

    constructor(
        private statesService: StatesService
    ) {
    }


    @HostListener("window:resize")
    onWindowResize() {
        // Höhe der Bilder an das hinterste Bild anpassen
        const height: number = this.shopImage.nativeElement.offsetHeight
        this.shopImageActive.nativeElement.style.height = `${height}px`
        // this.darkFilterDiv.nativeElement.style.height = `${height}px`
        // this.typoImage.nativeElement.style.height = `${height}px`

        // Höhe des Bildes für globale Verwendung weitergeben
        this.statesService.galleryImageHeight$.next(height)
    }


    ngOnInit(): void {
        setInterval(this.onWindowResize.bind(this), 300)
    }


    get highlighted() {
        return this.mapPointHovered$.value || this.hovered$.value
    }


}
